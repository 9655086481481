<template>
  <van-tabs color="#367BF4" sticky v-model="tabsActive" @click="tabsOnClick">
    <van-tab :title="item.title" :name="item.name" :class="item.class" v-for="(item, index) in tabsList" :key="'' + item.name + index" />
  </van-tabs>
</template>

<script>
import Vue from 'vue';
import { Tab, Tabs } from 'vant';

Vue.use(Tab);
Vue.use(Tabs);

export default {
  name: 'tabsTab',
  components: {},
  props: {
    defaultName: {
      type: [Number, String],
      default: 0
    },
    tabsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tabsActive: ''
    };
  },
  created() {
    this.tabsActive = this.defaultName;
  },
  methods: {
    tabsOnClick(name, title) {
      const routeName = this.tabsList.find(item => item.name === name)?.routeName;
      if (routeName && routeName !== this.$route.name) {
        this.$router.push({
          name: routeName,
          query: { ...this.$route.query }
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.van-tabs {
  // position: fixed;
  // width: 100%;
  // z-index: 9;
  // box-sizing: border-box;
  // left: 0px;
  // top: 0px;
  position: sticky;
  top: 0;
  z-index: 9;
}
/deep/ .van-tab--active {
  font-weight: 700;
}
</style>